export const Messages = {
    AGENT_REGISTERED: "agent registered",
    AGENT_DISCONNECTED: "agent disconnected",
    AGENT_DELETED: "agent deleted",
    EXECUTE_COMMAND: "execute command",
    LIST_AGENTS: "list agents",
    LIST_AGENTS_RESPONSE: "list agents response",
    TAKE_SCREENSHOT: "take screenshot",
    TAKE_SCREENSHOT_RESPONSE: "take screenshot response",
    TELEMETRY: "telemetry",
    REGISTERED: "registered",
    URL_CHANGED: "url changed"
}