import * as io from 'socket.io-client';
import { Messages } from '../server/messages';
import { TrackJS } from './tracker/index.esm.js';

var scriptEl = document.querySelector("[data-consolejs-channel]");
var channelId = scriptEl.getAttribute("data-consolejs-channel");
const remoteJsUrl = (scriptEl as any).src.replace("/agent/agent.js", "");

const socket = io(remoteJsUrl, {
    path: "/sockets",
    query: {
        channelId: channelId,
        role: "agent",
        id: sessionStorage.getItem("agentId") || "",
        userAgent: (window as any).navigator.userAgent,
        url: window.location.toString()
    }
});
socket.on("connect", () => {
})

socket.on(Messages.REGISTERED, (id: string) => {
    if(id === null) {
        console.log("Server is busy and no more agents can connect to this channel.");
        return;
    }
    sessionStorage.setItem("agentId", id);
    (window as any).agentId = id;
});

socket.on(Messages.EXECUTE_COMMAND, (data) => {
    var result = eval(data.command);
    var serializedResult;
    try {
        if(result instanceof HTMLElement) {
            var serializer = new XMLSerializer();
            serializedResult = serializer.serializeToString(result)
        }
        else {
            serializedResult = JSON.stringify(result);
        }
    }
    catch(e) {
        serializedResult = result.toString();
    }
    socket.emit(Messages.TELEMETRY, {
        type: "m",
        key: "command" + (+new Date()),
        item: {
            command: data.command,
            result: serializedResult
        }
    });
})

socket.on(Messages.TAKE_SCREENSHOT, async (data) => {
    var html2canvas = await import("html2canvas");
    var canvas = await html2canvas(document.querySelector("body"), {
        proxy: `${remoteJsUrl}/getImage`,
        logging: false,
        imageTimeout: 1000
    });
    socket.emit(Messages.TAKE_SCREENSHOT_RESPONSE, {
        viewerId: data.viewerId,
        image: canvas.toDataURL()
    })
});

(window as any).telemetry = [];
TrackJS.install({
    token: '1234',
    onError: function(error) {
        // Clean out things we really don't need.
        error.console = [];
        error.nav = [];
        error.network = [];
        error.visitor = [];

        // Piggyback on the telemetry stuff
        socket.emit(Messages.TELEMETRY, {
            key: error.timestamp + "error",
            type: "error",
            item: error
        });

        return false;
    },
    onTelemetry: function(type, key, item) {
        // Don't emit network events from our websocket or to our domain.
        if (type === 'n' && item.url.indexOf(remoteJsUrl) >= 0) { return; }

        (window as any).telemetry.push({ type: type, item: item });
        socket.emit(Messages.TELEMETRY, {
            key: key,
            type: type,
            item: item
        });

        if(type === "h") {
            socket.emit(Messages.URL_CHANGED, {
                url: window.location.toString()
            })
        }
    }
});
